import React, { useState, useEffect } from 'react';

const InfiniteScrollPhotos = () => {
  const [photos, setPhotos] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);

  const loadingMessages = [
    'yes the avatars are forever...',
    'true infinite loading...',
    'source code from JR...',
    'more on the way...',
    'yessssss more on the way...',
    'forever as in forever...'
  ];

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      const newPhotos = Array.from({ length: 10 }, (_, index) => ({
        id: photos.length + index + 1,
        url: `https://api.dicebear.com/9.x/adventurer/svg?seed=${Math.random().toString(36).substr(2, 9)}&flip=false`
      }));
      setPhotos(prevPhotos => [...prevPhotos, ...newPhotos]);
      setLoading(false);
      setLoadingMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
    }, 3000); // delay
  }, [page]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      loading
    ) {
      return;
    }
    setPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="infinite-scroll-container">
      <div className="photos-list">
        {photos.map(photo => (
          <div key={photo.id} className="photo-item">
            <img src={photo.url} alt={`Photo ${photo.id}`} />
          </div>
        ))}
        {loading && (
          <div className="loading-indicator">
            {loadingMessages[loadingMessageIndex]}
          </div>
        )}
      </div>
      <style jsx>{`
        .infinite-scroll-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .photos-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
        .photo-item {
          margin: 10px;
        }
        .photo-item img {
          width: 150px;
          height: 150px;
          object-fit: cover;
        }
        .loading-indicator {
          font-size: 18px;
          color: #333;
          margin-top: 20px;
        }
      `}</style>
    </div>
  );
};

export default InfiniteScrollPhotos;


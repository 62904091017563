import React from 'react';
import './App.css';
import InfiniteScrollPhotos from './InfiniteScrollPhotos';
import ReactLogo from './logo.svg';
import MaterialSymbolsKeyboardDoubleArrowDownRounded from './MaterialSymbolsKeyboardDoubleArrowDownRounded';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={ReactLogo} className="App-logo" alt="React Logo" />
        <p className="App-description">
          A fullstack App demonstrating infinite scroll.<br />
          Dicebear API. React App. Cloudflare DNS. Webpack.<br />
        </p>
        <h2>
          <img src="https://api.iconify.design/material-symbols:keyboard-double-arrow-down-rounded.svg?color=%23b18cfe" height="100px" width="100px" alt="Keyboard Double Arrow Down Rounded Icon" />
</h2>       
  <h6> npm create cloudflare@latest infinity-app -- --framework=react</h6>
 </header>
      <main className="App-main">
        <InfiniteScrollPhotos />
      </main>
      <footer className="App-footer">
        <p>made with&nbsp;&#10085;&nbsp;<a href="https://forever.jessejesse.com">forever.JesseJesse.com</a>&nbsp;&copy;2024</p>
      </footer>
    </div>
  );
}

export default App;

